var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",staticStyle:{"margin-top":"80px"}},[(_vm.loading)?_c('div',{staticStyle:{"margin-top":"100px","justify-content":"center","align-items":"center","display":"flex"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"turquoise","indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('swiper',{ref:"swiper",staticClass:"swiper d-none d-sm-none d-md-block",attrs:{"options":_vm.mainSwiperOption}},[_vm._l((_vm.banner),function(bannerItem){return _c('swiper-slide',{key:bannerItem.imageUrl,staticClass:"mainBanner"},[_c('div',{staticClass:"mainBanner",style:({
            'background-image': 'url(' + bannerItem.imageUrl + ')',
            'background-size': '100% 100%',
            'border-radius': '30px',
            cursor: 'pointer',
          }),on:{"click":function($event){return _vm.handleBannerClick(bannerItem)}}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2),_c('swiper',{ref:"swiper",staticClass:"swiper d-sm-block d-md-none",attrs:{"options":_vm.mainSwiperOption}},[_vm._l((_vm.banner),function(bannerItem){return _c('swiper-slide',{key:bannerItem.imageUrl,staticClass:"mainBanner"},[_c('div',{staticClass:"mainBanner",style:({
            'background-image': 'url(' + bannerItem.imageUrl + ')',
            'background-size': '100% 100%',
            'border-radius': '30px',
            cursor: 'pointer',
          }),on:{"click":function($event){return _vm.handleBannerClick(bannerItem)}}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1):_vm._e(),(!_vm.loading)?_c('div',{staticStyle:{"margin-top":"40px"}},_vm._l((_vm.filterCategories),function(category,index){return _c('div',{key:category.id},[(index == 6)?_c('div',[_c('div',[_c('div',[_vm._m(0,true),_c('swiper',{ref:"swiper",refInFor:true,staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.dashboard.dashboardBooks),function(book){return _c('swiper-slide',{key:book.id,staticStyle:{"padding":"10px"}},[(!book.courseBook)?_c('v-card',{staticClass:"zoom",style:({
                    'background-image': 'url(' + book.imageUrl + ')',
                    'background-size': '100% 100%',
                    'padding-top': '60%' /* 1:1 Aspect Ratio */,
                    position: 'relative',
                    width: '100%',
                  }),attrs:{"elevation":"2"},on:{"click":function($event){return _vm.openBook(book.id)}}},[(book.url)?_c('v-btn',{staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"}},[_vm._v("Ver Libro")]):_vm._e()],1):(book.courseBook)?_c('v-card',{staticClass:"zoom",style:({
                    'background-image': 'url(' + book.newImageUrl + ')',
                    'background-size': '100% 100%',
                    'padding-top': '60%' /* 1:1 Aspect Ratio */,
                    position: 'relative',
                    width: '100%',
                  }),attrs:{"elevation":"2"},on:{"click":function($event){return _vm.goToCourse(book.id)}}},[(!book.isPaidCourse)?_c('v-btn',{staticClass:"d-none d-sm-flex",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"},on:{"click":function($event){return _vm.goToCourse(book.id)}}},[_vm._v("Ver Libro")]):_vm._e(),(book.isPaidCourse)?_c('v-btn',{staticClass:"d-none d-sm-flex",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"},on:{"click":function($event){return _vm.goToCourse(book.id)}}},[_vm._v("Precio: "+_vm._s(_vm._f("toCurrency")(book.price)))]):_vm._e()],1):_vm._e(),_c('h2',{staticClass:"courseName"},[_vm._v(_vm._s(book.name))])],1)}),_c('div',{staticClass:"swiper-button-prev",staticStyle:{"color":"purple"},attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",staticStyle:{"color":"purple"},attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)])]):_vm._e(),_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"img-title",attrs:{"src":category.titleImageUrl}})]),_c('swiper',{ref:"swiper",refInFor:true,staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[(category.certifications.length > 0)?_vm._l((category.certifications.filter(
              function (x) { return x.id !== 2 || (x.id === 2 && _vm.userHasMaster); }
            )),function(certificacion){return _c('swiper-slide',{key:certificacion.id,staticStyle:{"padding":"10px"}},[_c('v-card',{staticClass:"zoom",style:({
                'background-image': 'url(' + certificacion.urlPortada + ')',
                'background-size': '100% 100%',
                'padding-top': '60%' /* 1:1 Aspect Ratio */,
                position: 'relative',
                width: '100%',
              }),attrs:{"elevation":"2"},on:{"click":function($event){return _vm.$router.push('/certificacion/' + certificacion.slug)}}},[_c('v-btn',{staticClass:"d-none d-sm-flex",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"},on:{"click":function($event){return _vm.$router.push('/certificacion')}}},[_vm._v(_vm._s((certificacion.available_purchase == false) ? 'Agotado' : ("Precio: " + (certificacion.precio | _vm.toCurrency))))])],1),_c('h2',{staticClass:"courseName"},[_vm._v(_vm._s(certificacion.nombre))])],1)}):_vm._e(),_vm._l((category.courses),function(course){return _c('swiper-slide',{key:course.id,staticStyle:{"padding":"10px"}},[_c('v-card',{staticClass:"zoom",style:({
              'background-image': 'url(' + course.newImageUrl + ')',
              'background-size': '100% 100%',
              'padding-top': '60%' /* 1:1 Aspect Ratio */,
              position: 'relative',
              width: '100%',
            }),attrs:{"elevation":"2"},on:{"click":function($event){return _vm.goToCourse(course.id)}}},[(!course.isPaidCourse)?_c('v-btn',{staticClass:"d-none d-sm-flex",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"},on:{"click":function($event){return _vm.goToCourse(course.id)}}},[_vm._v("Ver Curso")]):_vm._e(),(course.isPaidCourse)?_c('v-btn',{staticClass:"d-none d-sm-flex",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"elevation":"2","depressed":"","dark":"","color":"turquoise"},on:{"click":function($event){return _vm.goToCourse(course.id)}}},[_vm._v("Precio: "+_vm._s(_vm._f("toCurrency")(course.price)))]):_vm._e()],1),_c('h2',{staticClass:"courseName"},[_vm._v(_vm._s(course.name))])],1)}),_c('div',{staticClass:"swiper-button-prev",staticStyle:{"color":"purple"},attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",staticStyle:{"color":"purple"},attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"img-title",attrs:{"src":"https://zertuimages.s3.us-west-2.amazonaws.com/portadas_categorias/categoria-libros-min.png"}})])}]

export { render, staticRenderFns }